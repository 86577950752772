<template>
  <Container class="rel fj-tween padh-4 mt-15 pb-20 col-gap-2 documentation" style="top: var(--nav-h); padding-bottom: calc(var(--space-unit)*10);">
    <div class="doc-aside-cont fd-col mt-3 rel" style="position: sticky; top: calc( var(--nav-h) + var(--space-unit)*3); width: -webkit-fill-available; height: max-content;">
      <aside class="doc-aside" style="font-size: 0.8rem">
        <ul style="list-style-type: none; margin-left: none; line-height: 1.4;">
          <li><a v-scroll-to="{ el: '#d1', duration: 160, easing: 'ease', offset: -96}">1. Installing the library</a></li>
            <ul>
              <li><a v-scroll-to="{ el: '#d1-1', duration: 160, easing: 'ease', offset: -96}">1.1 Import script</a></li>
              <li><a v-scroll-to="{ el: '#d1-2', duration: 160, easing: 'ease', offset: -96}">1.2 Download script</a></li>
            </ul>
          <li><a v-scroll-to="{ el: '#d2', duration: 160, easing: 'ease', offset: -96}">2. Importing an artwork</a></li>
            <ul>
              <li><a v-scroll-to="{ el: '#d2-1', duration: 160, easing: 'ease', offset: -96}">2.1 Setting html mark up</a></li>
              <li><a v-scroll-to="{ el: '#d2-2', duration: 160, easing: 'ease', offset: -96}">2.2 Required properties</a></li>
              <li><a v-scroll-to="{ el: '#d2-3', duration: 160, easing: 'ease', offset: -96}">2.3 Options</a></li>
              <li><a v-scroll-to="{ el: '#d2-4', duration: 160, easing: 'ease', offset: -96}">2.4 Methods</a></li>
            </ul>
          <li><a href="#"></a></li>
        </ul>
      </aside>
    </div>
    <div class="doc-main fd-col" style="width: 80%;">
      <h1 id="title" class="mb-6">Documentation</h1>
      <p>Spritebooth comes with a <pre class="pre-code">Javascript</pre> library that helps importing, displaying and animating artworks into html pages.</p>
      
      <h2 id="d1" class="mt-6">1. Installing the library</h2>
      <h3 id="d1-1" class="mt-4">1.1 Import script</h3>
      <p>You can import sbx library with the following <pre class="pre-code">html</pre>snippet :</p>
      <div class="mt-3">
        <vue-code-highlight language="html">
          <pre>
          {{ lib_install }}
          </pre>
        </vue-code-highlight>
      </div>
      <h3 id="d1-2" class="mt-4">1.2 Download script</h3>
      <p>The latest sbx engine version is <pre class="pre-code">sbx-engine-canvas@1.3.2.js</pre></p>
      
      <h2 id="d2" class="mt-6">2. Importing an artwork</h2>
      
      <h3 id="d2-1" class="mt-4">2.1 Setting the html mark up</h3>
      <p class="mt-3">Let's assume you have the following <pre class="pre-code">html</pre> structure :</p>
      <div class="mt-3">
        <vue-code-highlight language="html">
          <pre>
          {{ default_structure }}
          </pre>
        </vue-code-highlight>
      </div>
      <p class="mt-2">To import an artwork into an html page, you will need to pass 2 <pre class="pre-code">String</pre> values to the <pre class="pre-code">Sprite.mount()</pre> method : </p>
      <ul class="mt-4 mb-2 ml-2">
        <li>A valid <pre class="pre-code">CSS selector String</pre> referring to the html element(s) the artwork will be injected in</li>
        <li>The <pre class="pre-code">key</pre> of the artwork you want to import ( can be found at <a href="https://www.spritebooth.com/gallery/1" target="_blank">spritebooth gallery <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </a> )</li>
      </ul>
      <div class="mt-3">
        <vue-code-highlight language="javascript">
          <pre>
            document.addEventListener("DOMContentLoaded", async (event) => {        
              await Sprite.mount([
                { key: `403f4eff-ea32-4b88-9a3f-159a3633c215`, target: ".yo" }
              ]);
            });
          </pre>
        </vue-code-highlight>
      </div>
      <Quotee>If your selector gather multiple elements, the artwork will be injected in all these elements.</Quotee>
      <div class="mt-3">
        <vue-code-highlight language="html">
          <pre>
          {{ multiple_structure }}
          </pre>
        </vue-code-highlight>
      </div>
      <h3 id="d2-2" class="mt-4">2.2 Required properties</h3>
      
      <table class="mt-3 mb-5 doc-table --green">
        <thead>
          <tr>
            <td>Name</td>
            <td>Type</td>
            <td>Default</td>
            <td>Valid values</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>key*</td>
            <td>String</td>
            <td>null</td>
            <td>spritebooth uid</td>
            <td>Key of the artwork to import</td>
          </tr>
          <tr>
            <td>target*</td>
            <td>String</td>
            <td>null</td>
            <td>'.backgound-1' | '#tree' | '[yo]'</td>
            <td>Any valid CSS selector</td>
          </tr>
        </tbody>
      </table>
      
      <h3 id="d2-3" class="mt-4">2.3 Options</h3>
      <table class="mt-3 mb-5 doc-table">
        <thead>
          <tr>
            <td>Name</td>
            <td>Type</td>
            <td>Default</td>
            <td>Valid values</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>on</td>
            <td>Object or Array of Objects</td>
            <td>null</td>
            <td>'trigger' | 'action'</td>
            <td>Declare event listeners and handlers attached to the `target` parameter</td>
          </tr>
          <tr>
            <td>trigger</td>
            <td>String</td>
            <td>null</td>
            <td>'click' | 'hover' | 'keydown:ArrowUp' |... mouseEvents, keyEvents, controllerEvents</td>
            <td>mouse events, keyboard events, controller events or custom events</td>
          </tr>
          <tr>
            <td>action</td>
            <td>String</td>
            <td>null</td>
            <td>'togglePlay' | 'toggleStop' | 'play' | 'stop' | 'pause' | 'eraseFrame' | 'paintFrame' | 'callback'</td>
            <td>A Sprite instance method String or a callback function</td>
          </tr>
          <tr>
            <td>firstPaint</td>
            <td>Boolean</td>
            <td>true</td>
            <td>true | false</td>
            <td>Whether or not the sprite's first frame should be painted right after being loaded</td>
          </tr>
          <tr>
            <td>autoplay</td>
            <td>Boolean</td>
            <td>true</td>
            <td>true | false</td>
            <td>Whether or not the sprite should play right after being loaded</td>
          </tr>
          <tr>
            <td>logs</td>
            <td>Boolean</td>
            <td>true</td>
            <td>true | false</td>
            <td>Determines if logs should be displayed</td>
          </tr>
          <tr>
            <td>playCount</td>
            <td>Number</td>
            <td>Infinity</td>
            <td>Any positive integer | Infinity</td>
            <td>Determines how many times sprite instance should be played</td>
          </tr>
          <tr>
            <td>playMode</td>
            <td>String</td>
            <td>'forward'</td>
            <td>'forward' | 'backward' | 'alternate'</td>
            <td>Determines in which direction the sprite instance should be played</td>
          </tr>
          <tr>
            <td>isPaused</td>
            <td>Boolean</td>
            <td>true</td>
            <td>true | false</td>
            <td>Pausing the animation</td>
          </tr>
          <tr>
            <td>isHidden</td>
            <td>Boolean</td>
            <td>true</td>
            <td>true | false</td>
            <td>If true, adds attribute hidden="true" on canvas dom element</td>
          </tr>
          <tr>
            <td>latency</td>
            <td>Number | Array of Number</td>
            <td>40</td>
            <td>Any positive integer</td>
            <td>Number of milliseconds before looping animation. The Array has to be at least as long as the frameLength of the sprite instance to work correctly</td>
          </tr>
          <tr>
            <td>pixelSize</td>
            <td>Number (integer)</td>
            <td>4</td>
            <td>Any positive integer</td>
            <td>Sets the pixel art square size</td>
          </tr>
        </tbody>
      </table>
      
      <h3 id="d2-4" class="mt-4">2.4 Methods</h3>
      <table class="mt-3 mb-5 doc-table --yellow">
        <thead>
          <tr>
            <td>Name</td>
            <td>Arguments</td>
            <td>Type</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>pause</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>unpause</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>togglePlay</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>toggleStop</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>play</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>stop</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>eraseFrame</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>resetFrameIndex</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>paintFrame</td>
            <td>target</td>
            <td>String</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4">
        <vue-code-highlight language="javascript">
          <pre>
            document.addEventListener("DOMContentLoaded", async (event) => {        
              await Sprite.mount([
                { key: `403f4eff-ea32-4b88-9a3f-159a3633c215`, target: "[yo]", options: { trigger: 'click', action: 'togglePlay'} },
              ]);
            });
          </pre>
        </vue-code-highlight>
      </div>
    </div>
  </Container>
</template>

<script type="javascript">
import { component as VueCodeHighlight } from 'vue-code-highlight';
import Container from '@/components/Container.vue'
import Quotee from '@/components/Quotee.vue'

export default{
  name: "Documentation",
  components: {
    Container,
    Quotee,
    VueCodeHighlight,
  },
  data(){
    return {
      lib_install: `
      <script src="https://spritebooth.com/lib/canvas">< /script>
      `,
      default_structure: `
      <div class="stage">
        <div class="yo"></div>
      </div>
      `,
      multiple_structure: `
      <div class="stage">
        <div class="yo"></div>
        <div class="yo"></div>
        <div class="yo"></div>
      </div>
      `,
    }
  },
  methods: {
  }
}
</script>

<style>
@import url('../assets/themes/window.css');
@import url('../assets/themes/prism-holi.css');

.pre-code{
  display: inline-flex;
  padding: calc(var(--space-unit)*1/3) calc(var(--space-unit)*1/2) calc(var(--space-unit)*1/6) calc(var(--space-unit)*1/2);
  margin: 0 calc(var(--space-unit)*1/2);
  background-color: var(--code-green);
  color: var(--color-o-accent);
  border-radius: calc(var(--size-unit)*1/2);
}
.documentation ul{
  list-style-type: disc;
}
.documentation a{
  text-decoration: underline;
  text-underline-offset: calc(var(--space-unit)*1);
  text-decoration-color: var(--color-accent);
}
.documentation h1,
.documentation h2,
.documentation h3,
.documentation h4 {
  width: fit-content;
  padding-left: calc(var(--space-unit)*1);
  padding-right: calc(var(--space-unit)*1);
  border-left: 2px solid var(--color-accent);
}
.documentation h2{
  background-color: var(--color-accent);
  color: var(--color-o-accent);
}
.documentation aside a{
  text-decoration: none;
}
.documentation li{
  padding-bottom: 8px;
}
.documentation aside ul{
  list-style-type: '•';
}
.documentation aside ul ul{
  padding-left: calc(var(--space-unit)*2);
}
.documentation aside ul ul li{
  padding-left: calc(var(--space-unit)* 2/3);
}
.doc-table{
  font-family: monospace;
}
.doc-table tbody td{
  border: 1px dotted var(--color-o-spread);
}
.doc-table thead td{
  border: 1px solid var(--color-spread);
}
.doc-table td{
  padding: calc(var(--space-unit)*1);
}
.doc-table thead{
  background-color: var(--color-accent);
  color: var(--color-o-accent);
}
.doc-table.--green thead{
  background-color: var(--code-green);
  color: var(--color-o-accent);
}
.doc-table.--yellow thead{
  background-color: var(--code-yellow);
  color: var(--color-o-accent);
}
</style>
