<template>
  <div style="display: flex;">
    <blockquote class="_quote">
      <slot></slot>
    </blockquote>
  </div>
</template>

<script>
  export default {
    name: 'Quotee',
  }
</script>

<style>
._quote{
  display: flex;
  padding: calc( var(--space-unit) * 2);
  margin-top: calc(var(--space-unit) * 4);
  margin-bottom: calc(var(--space-unit) * 3);
  border: 1px dotted var(--code-blue);
  border-left: 4px solid var(--code-blue);
  color: var(--color-o-spread);
  background-color: var(--code-blue-40);
  border-radius: 0 4px 4px 0 ;
}
.--blue{
  
}
</style>
